import DOMPurify from "dompurify"
import React, { FC } from "react"

import { create } from "src/helpers/bem"

import styles from "./Content.module.scss"

const bem = create(styles, "Content")

export type ContentProps = {
  label?: string
  content: string
  helperText?: string
}

export const Content: FC<ContentProps> = ({ label, content, helperText }) => (
  <div className={bem()}>
    {label && <span className={bem("title")}>{label}</span>}
    {content && (
      <div
        className={bem("content")}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(content, {
            ADD_ATTR: ["target"],
          }),
        }}
      ></div>
    )}
    {helperText && <span className={bem("helperText")}>{helperText}</span>}
  </div>
)
