import NoPhotographyIcon from "@mui/icons-material/NoPhotography"
import { ListItem, ListItemText, TextField } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { ButtonProps } from "src/components/common/button"
import { Checkbox } from "src/components/common/checkbox"
import { Dialog } from "src/components/common/dialog"
import { LazyImage } from "src/components/common/lazyImage"
import { BasicLayoutStyleProps } from "src/components/layouts/basic"
import { create } from "src/helpers/bem"
import { formatPrice } from "src/helpers/price"
import { postIncident } from "src/queriesXRM/incident"
import { Dialogs, mergeDialog, useDialog } from "src/states/dialogs"
import { useLocale } from "src/states/locale"
import { TranslationMessages } from "src/translations"

import { xRMApiSalesorderDetail } from "src/types/xRM"

import styles from "./ShopOrderDetailsCancelOrderDialog.module.scss"
import { ShopOrderDetailsItemStatus, isCancelled } from "./ShopOrderDetailsItemStatus"

const bem = create(styles, "ShopOrderDetailsCancelOrderDialog")

export type ShopOrderDetailsCancelOrderDialogProps = {
	messages: TranslationMessages
	style: BasicLayoutStyleProps
}

export const ShopOrderDetailsCancelOrderDialog: FC<ShopOrderDetailsCancelOrderDialogProps> = ({
	messages,
	style
}) => {
	const locale = useLocale()
	const { open, order, incidents } = useDialog('shopOrderDetailsCancelOrderDialog') as Dialogs['shopOrderDetailsCancelOrderDialog']
	const [ reason, setReason ] = useState('')
	const [ selectedItems, setSelectedItems ] = useState<xRMApiSalesorderDetail[]>([])
	const m = messages.pages.shop.orders.details.cancelOrderDialog

	const noReasonGiven = reason === ''

	const noItemsSelected = selectedItems.length === 0

	const items: xRMApiSalesorderDetail[] = [
		{
			id: 'all'
		},
		...order?.salesorderDetails as xRMApiSalesorderDetail[] ?? [],
	]

	const allItemsCancelled = items.slice(1).every(item => isCancelled(item, incidents))

	const firstRowButtons: ButtonProps[] = [
		{
			title: m.buttons.cancelOrder,
			variant: 'contained',
			disabled: noReasonGiven || noItemsSelected,
			onClick: async () => {
				mergeDialog('shopOrderDetailsCancelOrderDialog', { incidentLoading: true })
				await postIncident({
					referenceRecordId: order?.id,
					description: reason,
					incidentType: 'Cancellation',
					incidentPositions: selectedItems
						.filter(i => i.id !== 'all')
						.map(i => ({
							salesorderDetailId: i.id
						}))
				})
				mergeDialog('shopOrderDetailsCancelOrderDialog', { shouldRefreshIncidents: true })
			}
		},
		{
			title: m.buttons.close,
		},
	]

	useEffect(() => {
		if (!open) {
			setReason('')
			setSelectedItems([])
		}
	}, [open])

	return (
		<Dialog
			closeOnClick
			firstRowButtons={firstRowButtons}
			open={open}
			style={style}
			title={m.title}
			onClose={() => mergeDialog('shopOrderDetailsCancelOrderDialog', { open: false })}
		>
			<div className={bem()}>
				<p className={bem('description')}>
					{m.description}
				</p>
				{items.map(item =>
					<ListItem
						key={item.id}
						disablePadding
						secondaryAction={
							<Checkbox
								disableRipple
								checked={selectedItems.some(i => i.id === item?.id)}
								disabled={item.id !== 'all' ? isCancelled(item, incidents) : allItemsCancelled}
								edge="end"
								tabIndex={-1}
								onChange={(_, checked) => item.id === 'all'
									? setSelectedItems(checked ? items.filter(i => !isCancelled(i, incidents)) : [])
									: checked
										? setSelectedItems([ ...selectedItems.filter(i => i.id !== 'all'), item as xRMApiSalesorderDetail])
										: setSelectedItems(selectedItems.filter(i => i.id !== 'all' && i.id !== item.id))
								}
							/>
						}
					>
						<ListItemText className={bem('listitem')}>
							<div className={bem('listitem__content')}>
								{ item.id === 'all'
									? <div className={bem('listitem__content__all')}>{m.items.all}</div>
									: <div className={bem('listitem__content__container')}>
										<div className={bem('listitem__content__image', { 'is-disabled': isCancelled(item, incidents)})}>
											{item.purchaseorderProduct?.images?.[0].src
												? <LazyImage
													alt={item.purchaseorderProduct?.images?.[0].alt ?? item.purchaseorderProduct?.name ?? ""}
													src={item.purchaseorderProduct?.images?.[0].src} />
												: <NoPhotographyIcon color="disabled" />}
										</div>
										<div className={bem('listitem__content__detail', { 'is-disabled': isCancelled(item, incidents)})}>
											<div className={bem('listitem__content__detail__name')}>
												{item.name}
											</div>
											<div className={bem("listitem__content__detail__price")}>
												<div>
													{formatPrice(item.pricePerUnit, locale)} x {item.quantity} {locale === 'de' ? item.unit?.name : item.unit?.englishName}
												</div>
											</div>
										</div>
										<div className={bem("listitem__content__detail__status")}>
											<ShopOrderDetailsItemStatus incidents={incidents} item={item} size={'small'} />
										</div>
									</div>
								}
							</div>
						</ListItemText>
					</ListItem>
				)}
				<TextField
					fullWidth
					multiline
					className={bem('reason')}
					label={m.reason.label}
					rows={5}
					value={reason}
					onChange={e => setReason(e.target.value)}
				/>
			</div>
		</Dialog>
	)
}