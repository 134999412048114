import { ImmutableObject } from "@hookstate/core"
import React, { FC } from "react"

import { AdvancedForms, AdvancedForm } from "src/components/common/advancedForm"
import { Dialog } from "src/components/common/dialog"
import { BasicLayoutStyleProps } from "src/components/layouts/basic"

import { addNotification } from "src/states/notifications"
import { mergeCart, useCart } from "src/states/shop/carts"
import {
  useProductAttributes,
  mergeProductAttributes,
  resetProductAttributes,
  resetProductAttributesForm,
  mergeProductAttributesFormField,
} from "src/states/shop/productAttributes"
import { ProductVariant } from "src/states/shop/productVariants"

import { TranslationMessages } from "src/translations"

export type ShopDetailProductAttributesDialogProps = {
  messages: TranslationMessages
  style: BasicLayoutStyleProps
}

export const ShopDetailProductAttributesDialog: FC<
  ShopDetailProductAttributesDialogProps
> = ({ messages, style }) => {
  const m = messages.pages.shop.detail.productFeatureDialog
  const cart = useCart()
  const { form, open, product, productVariant } = useProductAttributes()

  const handleAddToCart = () => {
    mergeCart({
      productVariant: productVariant as ImmutableObject<ProductVariant>,
      productAttributeValues: form[0].fields
        .filter(
          (formField) => (formField.field === 'Amount' || (!formField.conditions || formField.conditions.display) && formField.type !== 'content'))
        .map((formField) => ({
          field: formField.field,
          value: formField.value as ImmutableObject<Record<string, unknown>>,
          referenceType:
          productVariant?.productAttributesForm?.fields?.find(
              (f) => f.field == formField.field,
            )?.referenceType ?? "ProductAttribute",
        })),
    })
    resetProductAttributes()
    addNotification({
      autoclose: true,
      variant: "success",
      content: messages.pages.shop.cart.snackbar.add,
    })
  }

  const hasAmountField = form[0]?.fields?.some((pa) => pa.field === "Amount")

  const fieldsRequiredButNotFilled =
    form[0]?.fields?.filter(
      (f) => (!f.conditions || f.conditions.display) && f.required && !f.value,
    ) ?? []

  const fieldsWithErrors =
    form[0]?.fields?.filter(
      (f) => (!f.conditions || f.conditions.display) && f.errorText,
    ) ?? []

  return (
    <Dialog
      closeOnClick
      fullWidth
      maxWidth="md"
      open={open}
      style={style}
      title={hasAmountField ? m.title : m.titleNoAdd}
      firstRowButtons={[
        {
          title: m.add,
          variant: "contained",
          disabled:
            fieldsRequiredButNotFilled.length > 0 ||
            fieldsWithErrors.length > 0 ||
            !hasAmountField,
          onClick: () => handleAddToCart(),
        },
        {
          title: m.cancel,
          variant: "text",
        },
      ]}
      onClose={() => {
        mergeProductAttributes({ open: false })
        resetProductAttributesForm()
      }}
    >
      <AdvancedForm
        data={form as AdvancedForms[]}
        handleMergeField={mergeProductAttributesFormField}
        handleReset={resetProductAttributesForm}
        id={cart.id}
        messages={messages}
      />
    </Dialog>
  )
}
