import { ImmutableObject } from "@hookstate/core"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import NoPhotographyIcon from "@mui/icons-material/NoPhotography"
import { CardActions, CardContent, Collapse, IconButton } from "@mui/material"

import { FC, useState } from "react"

import { Button } from "src/components/common/button"
import { Card } from "src/components/common/card"
import { LazyImage } from "src/components/common/lazyImage"
import { create } from "src/helpers/bem"

import { formatPrice } from "src/helpers/price"
import { useTranslation } from "src/hooks/translation"
import { useLocale } from "src/states/locale"
import {
  CartProduct,
  getCartProductAmount,
  mergeCart,
} from "src/states/shop/carts"

import { xRMApiField, xRMApiFieldValueDictionary } from "src/types/xRM"

import styles from "./ShopCartProductCard.module.scss"

const bem = create(styles, "ShopCartProductCard")

export type ShopCartProductCardProps = {
  item: ImmutableObject<CartProduct>
  displayDetails?: boolean
}

export const ShopCartProductCard: FC<ShopCartProductCardProps> = ({ item, displayDetails }) => {
  const locale = useLocale()
  const translation = useTranslation()
  const m = translation.messages.pages.shop.cart.productCard
  const product = item.productVariant!
  const productImage = product?.images?.[0]
  const [detailsExpanded, setDetailsExpanded] = useState(false)

  const handleExpandClick = () => {
    setDetailsExpanded(!detailsExpanded)
  }

  const hasAttributeValue = (attribute: xRMApiFieldValueDictionary) => {
    return attribute.value !== undefined && attribute.value !== null;
  }

  const modifier = {
    "is-expanded": detailsExpanded,
  }

  const hasAttributes = item.productAttributeValues?.some(
    (attribute) =>
      (attribute.referenceType === "ProductAttribute" ||
        attribute.referenceType === "PONumber" || attribute.referenceType === "Pretix") &&
        attribute.value != null,
  )

  const hasMandatoryProducts =
    item.productVariant?.mandatoryProducts &&
    item.productVariant?.mandatoryProducts.length > 0

  const renderAttributeLabelAndValue = ({ label, value }: { label: any, value: any }) => {
    return (
      <div className={bem("attributes")}>
        <span className={bem("attributes__label")}>{label}:</span>
        <div>{value}</div>
      </div>
    )
  }

  const getFieldValue = (field: xRMApiField, attributeValue: any) => {
    switch (field?.type) {
      case "select":
        if (Array.isArray(attributeValue)) {
          return attributeValue
            .map((v) => field.items?.find((i) => i.key === v)?.value)
            .join(", ");
        } else {
          return field.items?.find((i) => i.key === attributeValue)?.value;
        }
      case "checkbox":
        return attributeValue?.toString() === "true"
          ? m.checkboxValueTrue
          : m.checkboxValueFalse;
      case "file":
        return m.fileValueText;
      default:
        return attributeValue?.toString();
    }
  }

  const parseAttribute = (attribute: xRMApiFieldValueDictionary) => {
    const fields = item.productVariant?.productAttributesForm?.fields
    let field = fields?.find((f) => f.field === attribute.field)

    if (attribute.referenceType === "ProductAttribute") {
      field = fields?.find((f) => f.id === attribute.field)
    }

    let label = field?.label
    if (attribute.referenceType === "Pretix") {
      label = `Pretix ${field?.label}`
    }

    const value = getFieldValue(field as xRMApiField, attribute.value)
    return renderAttributeLabelAndValue({ label, value })
  }

  return (
    <Card>
      <CardContent className={bem("contents")}>
        <div className={bem()}>
          <div className={bem("amount")}>
            <div className={bem("counter")}>
              <div className={bem("counter__widget")}>
                <IconButton
                  color="primary"
                  disabled={
                    getCartProductAmount(item) >=
                    (item?.productVariant?.maximumOrderAmount ?? 1000000)
                  }
                  onClick={() => mergeCart(item, (amount) => amount + 1)}
                >
                  <KeyboardArrowUpIcon />
                </IconButton>
                <div className={bem("counter__sum")}>
                  {getCartProductAmount(item)}
                </div>
                <IconButton
                  color="primary"
                  disabled={getCartProductAmount(item) <= 1}
                  onClick={() => mergeCart(item, (amount) => amount - 1)}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
              </div>
              <div className={bem("counter__unit")}>{product.unit}</div>
            </div>
          </div>
          <div className={bem("image")}>
            {productImage?.src ? (
              <LazyImage
                alt={productImage.alt ?? product.name ?? ""}
                src={productImage.src}
              />
            ) : (
              <NoPhotographyIcon color="disabled" />
            )}
          </div>
          <div className={bem("info")}>
            <div className={bem("info__name")}>{product.name}</div>
            <div className={bem("sum")}>
              à {formatPrice(product.price, locale)}
            </div>
            <div className={bem("total")}>
              {formatPrice(product.price! * getCartProductAmount(item), locale)}
            </div>
          </div>
        </div>
      </CardContent>
      <CardActions className={bem("actions")}>
        {displayDetails && (hasAttributes || hasMandatoryProducts) ? (
          <Button
            aria-expanded={detailsExpanded}
            startIcon={<ExpandMoreIcon className={bem("actions__icon", modifier)}/>}
            title={m.expand}
            variant="text"
            onClick={handleExpandClick}
          />
        ) : null}
        <div>
          <Button
            startIcon={<HighlightOffIcon />}
            title={m.remove}
            variant="text"
            onClick={() => mergeCart(item, (_) => 0)}
          />
        </div>
      </CardActions>
      <Collapse unmountOnExit in={detailsExpanded} timeout="auto">
        <CardContent>
          {hasMandatoryProducts ? (
            <div className={bem("attributes")}>
              <div className={bem("attributes__label")}>
                {m.mandatoryProductsLabel}
              </div>
              <div className={bem("attributes__value")}>
                {item?.productVariant?.mandatoryProducts?.map((p, index) => (
                  <div key={index}>
                    {p.quantity != null
                      ? p.quantity * getCartProductAmount(item) + " x " + p.name
                      : p.name}
                    {index < (item?.productVariant?.mandatoryProducts?.length ?? 0) - 1 && ', '}
                  </div>
                ))}
              </div>
            </div>
          ) : null
          }
          <div>
            {item.productAttributeValues
              ?.filter((a) => a.referenceType !== "OrderAmount")
              .map((attribute) =>
                hasAttributeValue(attribute) ? (
                  <div key={attribute.field}>
                    <div>{parseAttribute(attribute)}</div>
                  </div>
                ) : null
              )}
          </div>
        </CardContent>
      </Collapse>
    </Card>
  )
}
