import { SetPartialStateAction, useHookstate } from "@hookstate/core"
import { xRMApiIncident, xRMApiPurchaseorder } from "src/types/xRM"

import { createAspState } from "./createAspState"

export type DialogState = {
  open: boolean
  disabled: boolean
}

export type Dialogs = {
  cartDialog: DialogState
  salesorderDialog: DialogState
  salesorderRequestDialog: DialogState
  shopOrderDetailsComplainDialog: DialogState & {
    order: xRMApiPurchaseorder | null
  },
  shopOrderDetailsCancelOrderDialog: DialogState & {
    order: xRMApiPurchaseorder | null
    incidents: xRMApiIncident[],
    shouldRefreshIncidents: boolean,
    incidentLoading: boolean,
  },
}

export const defaultDialogState: DialogState = {
  open: false,
  disabled: false
}

export const defaultDialogs: Dialogs = {
  cartDialog: {
    ...defaultDialogState
  },
  salesorderDialog: {
    ...defaultDialogState
  },
  salesorderRequestDialog: {
    ...defaultDialogState
  },
  shopOrderDetailsComplainDialog: {
    ...defaultDialogState,
    order: null
  },
  shopOrderDetailsCancelOrderDialog: {
    ...defaultDialogState,
    order: null,
    incidents: [],
    shouldRefreshIncidents: false,
    incidentLoading: false,
  },
}

const state = createAspState<Dialogs>({ ...defaultDialogs })

export const getDialog = (dialog: keyof Dialogs) => state.nested(dialog).get()

export const setDialog = <K extends keyof Dialogs>(dialog: K, value: Dialogs[K]) => state.nested(dialog).set(value)

export const useDialog = (dialog: keyof Dialogs) => useHookstate(state.nested(dialog)).get()

export const mergeDialog = <K extends keyof Dialogs>(dialog: K, value: SetPartialStateAction<Dialogs[K]>) =>
  state.nested(dialog).merge(value)
